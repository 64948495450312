import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import imgMobile from "../../assets/image/jpeg/micro10.jpg";
import imgMicro from "../../assets/image/jpeg/micro15.jpg";


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;


const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);



const MextraPage = () => (
  <>
<Separator />
    {/* <!-- Content section 2 --> */}
    <Section py={4} bg="#f7f7fb">
      <Container className="pb-3 pt-3">

      <Row>
  <Col>
  <SecondText className="pb-4">
  How does Skinpen® Precision Microneedling London remodel your skin?
  </SecondText>

  <Text color="dark">
  Skinpen® Precision is a microneedling device that utilizes Collagen Induction . It creates controlled micro channels at specifically targeted depth that activates your body’s natural skin-healing power. This initiates the release of natural growth factors to improve collagen formation and cellular tissue remodelling, returning your skin to a healthier looking and youthful appearance without the formation of scarring. <br /> <br /> 

Collagen and elastin are the essential protein fibers in the skin that gives it support, firmness and elasticity. As we age, those fibers break down leading to fine lines, wrinkles, sagging skin and large pores. <br /> <br /> 

Collagen plays a key role in scars, such as those formed from acne. Improper signals in pigmentation lead to dyschromia such as Melasma, Vitiligo and Sun or Age Spots (Solar Lentigines). Skin remodelling can have an important role in the improved appearance of these conditions and the regenerating collagen and elastin can help in getting a more youthful appearance and even skin tone overall. <br /> <br /> 

Skinpen® can be used on all areas of the face and body and can treat a number of concerns. <br /> <br /> 

There are three phases involved in the wound-healing process: <br />

<b>Phase 1</b>:  <br />
The sterile tip generates measured and depth controlled microchannels to the skin that trigger your immune system to cleanse the new wound, prompting your skin to remove debris, increase blood flow and release growth factors that begin to create new tissue.<br /> <br /> 

<b>Phase 2</b>: <br />

The skin then starts to rebuild the wound with new , by releasing Growth Factors, Glycoproteins and Collagen III and develops a new network of blood vessels.<br /> <br /> 

<b>Phase 3</b>: <br />

At this stage skin tissue remodelling takes place with the replacement of Collagen III with Collagen I (springy, elastic and form even texture). The wound is then replaced with new dermal tissues and blood vessels.





        </Text>

    
  </Col>
</Row>


<br /> <br />

<Row>
  <Col>
  <SecondText>
  What to expect after Microneedling London?
  </SecondText>

  <Text color="dark">
  Immediately after the treatment patient will experience tightened and glowing skin. Redness or slight sunburn look will be noticed which may last between 30mins-24hrs, this relatively varies depending on skin sensitivity. <br /> <br />Most of the redness and peeling will be mostly finished by the second day. After the 24 hrs patient can start applying sunscreen, makeup and continue using the creams provided after treatment.
        </Text>

    
  </Col>
</Row>

<br /> <br />

<Row>
  <Col>
  <SecondText className="pb-4">
  Why choose Skinpen® Precision Microneedling?  
  </SecondText>

  <div
              className=""
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img  src={imgMicro} alt="Microneedling Skinpen®" className="rounded shadow" /> <br /> <br />
            </div>

            
          <table className="table_black">
 
 <tbody>
 <tr>
     <td ><Span color="#00afc9">Skinpen® Precision Microneedling</Span></td>
     <td ><Span color="#00afc9">Dermaroller/Other Microneedling devices</Span></td>
     
   </tr>
   <tr>
   <td >FDA approved 

</td>
     <td>Not FDA approved 

</td>
   
  
   </tr>
  
 
   <tr>
   <td >Medically licensed 

</td>
     <td>Not medically licenced 

</td>
  
   </tr>
   <tr>
   <td>Licensed to treat the following indications:<br />
-	Acne scarring <br />
-	Hyperpigmentation (Vitiligo, dark age spots, melasma) <br />
-	Fine lines and wrinkles 
</td>
     <td>Not approved for the treatment of claimed conditions</td>
     
   </tr>
 
   <tr>
   <td>Validated to deliver 1,600 microchannels per second 

</td>
     <td>Dermaroller would need around 15 passes on the same area compared to Skinpen® which only needs 1 pass </td>
     
   </tr>
 
   <tr>
   <td>Precise and controlled depth which is maintained throughout the treatment. Targets different depth to deliver desired outcomes</td>
     <td>Depth is not controlled and varies depending on the manual pressure applied. Uneven pressure can cause uneven channels  


</td>
     
   </tr>

   <tr>
   <td>Glides through the skin surface with even pressure, preventing skin damage (scarring, streaking, marking)

</td>
     <td>Does not smoothly glide. Likely to scar and leave mark on skin surface as other devices rotate into the tissue, cutting only when travelling inwards 



</td>
     
   </tr>



   <tr>
   <td>Single-use sterile needles used

</td>
     <td>Needles are not sterile or single use – even disinfecting will not remove 100% of bacteria



</td>
     
   </tr>



   <tr>
   <td>Sharp needle that penetrates the skin perpendicularly at 90

</td>
     <td>Rolling mechanism can cause micro-tears 



</td>
     
   </tr>



   <tr>
   <td>Deliver controlled results throughout  

</td>
     <td>Uncontrolled injury to skin takes longer to heal 



</td>
     
   </tr>



   <tr>
   <td>Maintain needle sharpness stays the same throughout the treatment up to 80 min. 

</td>
     <td>Other devices become blunt and cause skin inflammation 



</td>
     
   </tr>
   <br />
 </tbody>
 
 
 </table>

    
  </Col>
</Row> 




   
      <Row>
  <Col>
  <SecondText className="pb-4">
  Skinpen® Precision Microneedling + PRP Facial
  </SecondText>

  <Text color="dark">
  <b>Skinpen® Precision Micro-Needling is the first step.</b> <br /> <br />
Skinpen® Precision micro-needling is done with a specialised equipment that has a collection of small, sterile needles. This is how the process usually goes:
<br /> <br />
Cleaning and Numbing: To ensure a clean surface, the treatment area is thoroughly cleaned. To reduce any potential discomfort during the treatment, a numbing lotion may be given.<br /> <br />

Needle Penetration: The Skinpen® Precision gadget is dragged gently across the skin to create regulated micro-channels. These minuscule punctures stimulate collagen and elastin formation, triggering the body's natural healing reaction.<br /> <br />

Collagen Stimulation: The micro-channels improve skincare product absorption and growth factor delivery, enabling skin regeneration. The procedure improves skin texture, reduces scars and wrinkles, and promotes general skin renewal.<br /> <br />

<b>PRP Facial Step 2</b><br /> <br />
Platelet-Rich Plasma, a concentrate of platelets and growth factors produced from your own blood, is used in PRP facials. This is how the process usually goes:
<br /> <br />
Blood Collection: Similar to a standard blood test, a little amount of blood is collected from your arm.<br /> <br />

PRP Separation: The collected blood is spun at a high speed in a centrifuge to separate the platelets and growth factors from the other components of the blood.
<br /> <br />
PRP Application: The PRP is applied to the micro-needled skin once it has been removed. The microchannels formed during the Skinpen® Precision treatment enable for better absorption and deeper penetration of the PRP into the skin.
<br /> <br />

Skin Rejuvenation: The growth factors in PRP enhance tissue healing, collagen formation, and cellular nutrition. This can aid in the improvement of skin tone, texture, and general renewal.
<br /> <br />
Combining Micro-Needling with PRP Facial: When combined with a PRP facial, the micro-channels generated by the micro-needling technique allow for improved absorption and utilisation of the growth factors and platelets present in the PRP. This combination improves overall outcomes by fostering greater skin renewal, enhanced texture, and a youthful shine.
<br /> <br />

<b>Benefits of combining Skinpen® Precision Micro-needling and PRP Facial </b>
<br /> <br />
Targeted Treatment for Specific conditions: By combining Skinpen® Precision micro-needling with PRP, a customised approach to addressing specific skin conditions is possible. Whether it's acne scars, hyperpigmentation, uneven skin texture, or symptoms of ageing, the combo treatment can more effectively address these issues by combining the collagen-stimulating benefits of micro-needling with the rejuvenating properties of PRP.
<br /> <br />
Improved Healing and Recovery: Because of its high platelet concentration, PRP has natural healing qualities. When administered to micro-needled skin, PRP can hasten healing by supplying growth factors that aid in tissue repair and regeneration. This can assist to reduce downtime and accelerate recovery after microneedling treatment.
<br /> <br />
Long-term outcomes: The combination of Skinpen® Precision micro-needling and PRP has the potential to produce long-term outcomes. Micro-needling-induced collagen remodelling, along with the regenerative effects of PRP, can contribute to long-term changes in skin texture, tone, and overall appearance.
<br /> <br />

        </Text>

    
  </Col>
</Row> 
  
        </Container>
        </Section>


        <SeparatorEnd />

  
  </>
);

export default MextraPage;



